(async () => {

    rpSituationsTimer();

    const animateDataNumber = document.querySelectorAll('.animate-value');

    let animateDataIndex = 0;
    const animateDataLength = animateDataNumber.length;

    for(; animateDataIndex < animateDataLength; animateDataIndex++) {
        const element = animateDataNumber[animateDataIndex];

        const startValue = element.dataset.numberStart;
        const endValue = element.dataset.numberEnd;
        const animationTime = parseInt(element.dataset.numberTime);
        const animationDelay = parseInt(element.dataset.numberDelay);
        const numberFormat = element.dataset.numberFormat;
        const extra = element.dataset.extra;

        animateNumbers(element, startValue, endValue, animationTime, numberFormat, animationDelay, formatCurrency, extra);
    }

})();

function rpSituationsTimer() {
    const rpSituations = document.querySelector(".rp-situations");
    if(!rpSituations) return;

    const releaseDate = 1713503972;

    const valueElement = rpSituations.querySelector("span");
    const invisible = rpSituations.querySelector(".invisible");

    const randomIntervalFunction = () => {
        const interval = Math.random() * 3500 + 100;

        const currentTs = Math.round(+new Date() / 1000);
        const count = Math.round((currentTs - releaseDate) / 1.4);//
        const numb = Math.round(count).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "x";
        valueElement.innerHTML = numb;//xx
        invisible.innerHTML = numb;

        setTimeout(randomIntervalFunction, interval);
    }
    randomIntervalFunction();
}

function animateNumbers(element, startNumber, endNumber, animationDuration, format, animationDelay, formattingFunction, extra) {

    startNumber = parseFloat(startNumber);
    endNumber = parseFloat(endNumber);

    const spanText = element.querySelector("span");

    spanText.innerHTML = formattingFunction(format, 0);

    setTimeout(()=> {
        let startTime = performance.now();
        const updateNumber = (currentTime) => {

            const elapsedTime = currentTime - startTime;
            const progress = Math.min(elapsedTime / animationDuration, 1);
            const animatedValue = startNumber + (endNumber - startNumber) * progress;

            spanText.innerHTML = formattingFunction(element.dataset.numberFormat, animatedValue);

            if (progress < 1) {
                requestAnimationFrame(updateNumber);
            }
        };

        requestAnimationFrame(updateNumber);
    }, animationDelay);

}

// // Example formatting function
function formatCurrency(format, value) {
    switch (format) {
        case 'currency':
            return Math.round(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " $";
        case 'number':
            return Math.round(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        default:
            return Math.round(value);
    }
//     const formattedValue = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(parseFloat(element.textContent));
//     element.textContent = formattedValue;
}

// Usage
