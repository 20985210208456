(()=>{
    const headlines = document.querySelectorAll('h2');
    const headlineLength = headlines.length;
    let headlinesIndex = 0;

    const getRandomCharIndex = (text, randomCharsArr) => {
        let randomChar = Math.floor(Math.random() * text.length);

        if(text.charCodeAt(randomChar) === 32 || text[randomChar] === ' ' || randomCharsArr.includes(randomChar) ) {
            randomChar = getRandomCharIndex(text, randomCharsArr);
        }

        return randomChar;
    }

    const scrambleText = (element, minTime, maxTime, timeout, randomCharLength) => {
        const min = Math.ceil(minTime);
        const max = Math.floor(maxTime);
        const random =  Math.floor(Math.random() * (max - min + 1)) + min;

        const text = element.textContent;
        let randomChars = [];



        // const text = element.textContent;
        // let randomChars = [];
        //
        // let randomCharIndex = 0;
        //
        // for(; randomCharIndex < randomCharLength; randomCharIndex++) {
        //     const currentRandomChar = getRandomCharIndex(text, randomChars);
        //     randomChars.push(currentRandomChar);
        // }
        //
        // const changeCharacterAtIndex = (text, index, newCharacter) => {
        //     return text.substring(0, index) + newCharacter + text.substring(index + 1);
        // }
        //
        // const changeInterval = setInterval(() => {
        //     let textIndex = 0;
        //     let newText = '';
        //     for(; textIndex < text.length; textIndex++) {
        //         if(randomChars.includes(textIndex)) {
        //             newText += String.fromCharCode(Math.floor(Math.random() * 94) + 33);
        //         } else {
        //             newText += text[textIndex];
        //         }
        //     }
        //
        //     element.innerHTML = newText;
        // }, random);
        //
        // setTimeout(() => {
        //     clearInterval(changeInterval);
        //     element.innerHTML = text;
        // }, timeout);
    }

    for(; headlinesIndex < headlineLength; headlinesIndex++) {
        const currentHeadline = headlines[headlinesIndex];
        scrambleText(currentHeadline, 1250, 2500, 2500, 2);
    }
})();
