(()=> {
    document.querySelectorAll(".accordion-item").forEach((el) => {
        el.addEventListener("click", () => {
            const headline = el.querySelector(".accordion-headline");
            const content = el.querySelector(".accordion-content");
            headline.classList.toggle("visible");
            content.classList.toggle("visible");
        });
    });
})();
