document.addEventListener('readystatechange', event => {

    const pagination = document.querySelectorAll(".pagination");

    let paginationIndex = 0;
    const paginationLength = pagination.length;

    for(; paginationIndex < paginationLength; paginationIndex++) {
        const currentPagination = pagination[paginationIndex];
        const paginationControllers = currentPagination.querySelector(".pagination-controller");
        const paginationControllerDots = currentPagination.querySelectorAll(".pagination-controller .page-controller");
        const pages = currentPagination.querySelectorAll(".page");
        const pagesArr = Array.from(pages);
        const paginationControllerDotsArr = Array.from(paginationControllerDots);

        let pagePaginationIndex = 0;
        let paginationInterval = null;

        const setPaginationInterval = () => {
            if(paginationInterval) clearInterval(paginationInterval);
            paginationInterval = setInterval(()=> {
                paginationChange(pagePaginationIndex + 1);
            }, 2000);
        }
        // setPaginationInterval();

        const paginationChange = (index) => {
            if(index < 0) return;
            if(index === pagePaginationIndex) return;

            if(index > (pagesArr.length - 1)) {
                index = 0;
            }

            pagesArr.map((page, index) => {
                page.classList.remove("active");
            });
            pagesArr[index].classList.add("active");

            paginationControllerDotsArr.map((paginationController, index) => {
                paginationController.classList.remove("active");
            });
            paginationControllerDotsArr[index].classList.add("active");

            pagePaginationIndex = index;
        }

        paginationControllers.addEventListener("click", event => {

            const index = [...paginationControllers.children].indexOf(event.target);
            paginationChange(index);
            // setPaginationInterval();
        });
    }

});
