(()=>{

// Select all .logo containers
    const logos = document.querySelectorAll(".logo");

// Function to make a single polygon blink
    function blinkPolygon(polygon) {
        const blinkCount = Math.floor(Math.random() * 4) + 2;
        let delay = 0;

        for (let i = 0; i < blinkCount; i++) {
            setTimeout(() => {
                polygon.style.transition = "opacity 50ms";
                polygon.style.opacity = "0";
                setTimeout(() => {
                    polygon.style.opacity = "1";
                }, 100);
            }, delay);

            delay += Math.random() * 70 + 20;
        }
    }

// Function to randomly blink polygons in a specific logo
    function randomBlinkLogo(logo) {
        const polygons = logo.querySelectorAll("polygon");
        const blinkChance = Math.random() > 0.8; // 20% chance to blink

        if (blinkChance) {
            polygons.forEach(polygon => {
                blinkPolygon(polygon); // Blink each polygon randomly
            });
        }
    }

// Function to randomly blink all logos
    function randomBlinkAll() {
        logos.forEach(logo => randomBlinkLogo(logo));
    }

    setInterval(randomBlinkAll, 250);
})();
