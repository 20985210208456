const modules = document.getElementById("modules");

const params = new URLSearchParams(window.location.search);
const module = params.get('module');
if (module) {
    const element = document.querySelector(`[data-module="${module}"]`);
    if (element) {
        element.classList.add('visible');

        const url = new URL(window.location.href);
        if (url.searchParams.has('module')) {
            url.searchParams.delete('module');
            history.replaceState(null, '', url.toString());
        }
    }
}

modules.addEventListener("click", e => {
    const moduleWindow = e.target.closest(".module-window");
    const moduleVisible = e.target.closest(".module.visible");

    const closeButton = e.target.closest(".close-button");

    if(moduleWindow && !closeButton) return;

    if(moduleVisible.classList.contains("visible")) {
        moduleVisible.classList.remove("visible");
    }
});

const dataModules = Array.from(document.querySelectorAll("[data-module-trigger]"));

const dataModuleLength = dataModules.length;
let dataModuleIndex = 0;

for(; dataModuleIndex < dataModuleLength; dataModuleIndex++) {
    const currentDataModule = dataModules[dataModuleIndex];

    currentDataModule.addEventListener("click", e => {
        const moduleTrigger = e.target.dataset.moduleTrigger;
        const openModule = document.querySelector("[data-module='"+ moduleTrigger +"']");

        if(!openModule) return;

        openModule.classList.add("visible");
    });
//xxx
}

