document.addEventListener('readystatechange', event => {
    const hashLinks = document.querySelectorAll('a[href^="#"]');
    const hashLength = hashLinks.length;
    let hashIndex = 0;
    for(; hashIndex < hashLength; hashIndex++) {
        hashLinks[hashIndex].addEventListener('click', scrollToTarget);
    }

    const scroller = document.getElementById("main");
    if(!scroller) return;

    const menuHeight = document.getElementById('menu').offsetHeight;
    const magicNumber = 0;

    function scrollToTarget(e) {
        e.preventDefault();

        const targetId = this.getAttribute('href').substring(1); // Remove the '#' from href
        const targetElement = document.getElementById(targetId);

        if (!targetElement) return;

        const targetPosition = targetElement.offsetTop - menuHeight - magicNumber; // Subtract menu height
        scroller.scrollTo({
            top: targetPosition,
            behavior: 'smooth'
        });
    }//


});

