(function(){
    const scrollEventMap = new Set();

    const main = document.getElementById("main");
    const menu = document.getElementById("menu");

    main.addEventListener('scroll', e => {
        scrollEventMap.forEach((func) => func(e));
    });


    document.body.addEventListener("click", e => {
        const isMenu = e.target.closest(".menu-items");
        const isMenuButton = e.target.closest(".responsive-menu");
        const menuOpened = document.body.classList.contains("responsive-menu-opened");
        const clientWidth = document.body.clientWidth;

        if(menuOpened)  {
            if(isMenu) return;
            document.body.classList.remove("responsive-menu-opened");
        } else {
            if((isMenuButton || isMenu) && clientWidth < 1000) {
                document.body.classList.add("responsive-menu-opened");
            }
        }

    });

    //Add BG color when scrolling
    scrollEventMap.add(e => {
        if(main.scrollTop > 10) {
            menu.classList.add("bg");
        } else {
            menu.classList.remove("bg");
        }
    });

})();
