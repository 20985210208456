let fadeInListener;

const scrollEvent = (e) => {
    let fadeElements = Array.from(document.querySelectorAll(".visible-on-scroll"));
    let fadeElementsLength = fadeElements.length;
    let fadeIndex = fadeElementsLength;

    if(fadeElementsLength === 0) {
        document.getElementById("main").removeEventListener("scroll", scrollEvent);
        fadeInListener = null;
    }

    //Reverse for loop, begin from behind and remove the element if it is visible
    for(; fadeIndex > 0; fadeIndex--) {
        const element = fadeElements[fadeIndex-1];

        const elementRect = element.getBoundingClientRect();
        const elementTop = elementRect.top;
        const elementBottom = elementRect.bottom;
        const elementHeight = elementRect.height;
        const viewportHeight = window.innerHeight;
        const isVisible = elementTop + (elementHeight / 2) < viewportHeight && elementBottom >= 0;

        if(!isVisible) continue;
        element.classList.remove("visible-on-scroll");
    }
};

fadeInListener = document.getElementById("main").addEventListener("scroll", scrollEvent);

