const connectButtons = document.querySelectorAll('.connect-button');
let connectIndex = 0;
const connectLength = connectButtons.length;
for(; connectIndex < connectLength; connectIndex++){
    connectButtons[connectIndex].addEventListener('click', (e) => {
        e.preventDefault();
        alert("Wir sind noch nicht Live. Schau doch in unserem Discord vorbei für Neuigkeiten, Sneak Peeks und um unsere Community kennenzulernen. https://discord.wastedv.de");
    });
}

const connectNumber = document.getElementById("connect-number-animation");

const connect = () => {
    window.location.href = "https://connect.wastedv.de";
};

if(connectNumber) {

    const totalNumbers = 7;
    let currentIndex = 3;

    const animateNumbers = () => {
        connectNumber.classList.add("animate");
        setTimeout(()=>{
            connectNumber.classList.remove("animate");
        }, 5);

        if (currentIndex < totalNumbers - 1) {
            currentIndex++;
            connectNumber.style.transform = `translateY(-${currentIndex * 1.25}rem)`;
        } else {
            connectNumber.classList.remove("animate");
            clearInterval(countdown);
            connect();
        }
    };

    connectNumber.style.transform = `translateY(-${currentIndex * 1.25}rem)`;
    const countdown = setInterval(animateNumbers, 1000);
}
